import React from "react"
// import Article from "../components/article"
import Cover from "../components/Cover"
import { Layout } from "../components/Layout";
import Seo from "react-seo-component";
import { useSiteMetadata } from "../hooks/use-site-metadata"

const IndexPage = () => {
  const {
    title,
    description,
    siteUrl,
    siteLanguage,
    siteLocale,
    socialimage,
    twitterUsername,
  } = useSiteMetadata();
  return (
      <Layout>
        <Seo
          title={`Home`}
          titleTemplate={title}
          description={description}
          pathname={siteUrl}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          image={socialimage}
          twitterUsername={twitterUsername}
        />
        <Cover />
      </Layout>
  );
}

export default IndexPage
