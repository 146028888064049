import React from "react"
import "../styles/_main.scss"
import styled from 'styled-components'
import { Container, Jumbotron } from "react-bootstrap"
import background from "../images/bg.jpeg"
import { Link } from "gatsby"
import { useSiteMetadata } from "../hooks/use-site-metadata"

// import CoverImage from "../images/sustainable-cover.jpg"
// import { StaticImage } from "gatsby-plugin-image"

const Section = styled.section`
height: 100%;
flex: 1 1 auto;
    .jumbotron {
        background-color: transparent;
        background-image: 
         
          linear-gradient(0deg, rgba(0,0,0,0.6404936974789917) 0%, rgba(255,255,255,0) 55%),
          url(${background});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        padding: 0;
        height: 100%;
        flex: 1 1 auto;
        margin-bottom:0;
        .cover-title {
            position: relative;
            padding: 0.2em .6em 0.3em;
            @media (min-width: 576px) {
              z-index: 2;
            } 
        }
    }
`;


export default function Article() {
  const { title, description } = useSiteMetadata();
  return (
    <Section className="h-100 d-flex flex-column">
      <Jumbotron className="d-flex flex-column" fluid>
        <Container className="d-flex align-items-start flex-column flex-grow-1 mb-5">
          <h1 id="charting-a-course-to-sustainable-action" className="cover-title mt-auto text-dark bg-warning font-weight-light">{title}</h1>
          <p className="text-light lead mt-3 mb-4">{description}</p>
          <Link className="btn btn-primary pt-2 pb-2 pl-5 pr-5 mb-5" to="/executive-summary">Read the report</Link>
        </Container>
      </Jumbotron>
    </Section>
  )
}